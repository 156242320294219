/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { mainDivClassName } from '../../utils/MainDivClassName';
import HeaderSurveys from '../../components/HeaderSurveys';
import { CompanyDataResponse, consultantService } from '../../services/consultantServices';
import { Typography } from '@material-tailwind/react';
import Button from '../../components/Button';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { setCompany, setLoggedIn } from '../../features/userSlice';
import { authService } from '../../services/authServices';
import CancelButton from '../../components/CancelButton';

type Props = {}
const TABLE_HEAD = ["Nombre", "NIT", "Gestionar"];

const CompanyManagement = (props: Props) => {
    const [companies, setCompanies] = useState<CompanyDataResponse[]>([])
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        setErrorMessage("")
        consultantService.getCompaniesData(
            (response) => {
                setCompanies(response.results)
            },
            (error) => {
                setErrorMessage("Error al obtener las empresas")
            },

        );
    }, [])

    const completeLogout = () => {
        dispatch(setLoggedIn(false));
        localStorage.clear();
        navigate('/');
    };

    const handleLogout = () => {
        setErrorMessage("")
        authService.logout(
            completeLogout,
            (err) => {
                if (err?.response?.status === 401) {
                    completeLogout();
                } else {
                    setErrorMessage("Error cerrando sesión")

                }
            }
        );
    };

    const managementClick = (name: string, id: string | undefined) => {
        if (!id) {
            setErrorMessage("Error: ID de la empresa no válido.")
            return;
        }
        dispatch(setCompany({
            companyId: id,
            companyName: name
        }));
        navigate(`/home`)
    }

    return (
        <>
            <div className={`grid place-items-center text-center justify-items-center gap-4  h-screen  laptop:border laptop:border-white desktop:border desktop:border-white py-5 ${mainDivClassName}`} >
                <div className='max-w-[580px] min-h-[700px] max-h-[700px] bg-white rounded-lg shadow-lg border-2 p-2 border-secondaryBrown overflow-y-auto'>
                    <div className="flex justify-center text-center min-h-[128px] mobile:h-auto bg-[#fdfced] rounded-t-lg">
                        <div className="flex justify-center items-center p-4">
                            <div className='grid'>
                                <h1 className="mobile:text-2xl text-4xl font-rounded font-semibold text-primaryOrange">
                                    Gestiona tus empresas
                                </h1>
                            </div>
                        </div>
                    </div>
                    {companies &&
                        <table className="w-full overflow-auto bg-white border-separate border border-spacing-x-2 border-transparent">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="text-center px-2 py-3 text-m  bg-secondaryGray text-secondaryBrown border-white rounded-lg">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                                placeholder={''}
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='text-secondaryBrown text-center'>
                                {companies.map(({ name, nit, id }, index) => (
                                    <tr key={`employee_${id}`} className="hover:bg-gray-50">
                                        <td className="px-2 py-3 text-m">
                                            <Typography variant="small" color="blue-gray" className="font-normal" placeholder={''}>
                                                {name}
                                            </Typography>
                                        </td>

                                        <td className="px-2 py-3 text-m">
                                            <Typography variant="small" color="blue-gray" className="font-normal" placeholder={''}>
                                                {nit}
                                            </Typography>
                                        </td>

                                        <td className="px-2 py-3 text-m">
                                            <button
                                                className='bg-primaryOrange text-m rounded-lg px-2 h-[36px] min-w-[170px] text-white font-semibold hover:bg-secondaryOrange '
                                                onClick={() => managementClick(name, id)}


                                            >
                                                Gestionar
                                            </button>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                    <span className=' text-m text-primaryRed'>{errorMessage}</span>
                </div>
                <CancelButton
                    text='Cerrar sesión'
                    onClick={handleLogout}
                />
            </div>
        </>
    )
}
export default CompanyManagement
