import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/MainLayout';
import { adminService, CompanyAdminData, ConsultantData } from '../../services/adminService';
import Button from '../../components/Button';
import SuccessModal from '../../components/SuccessModal';
import CancelButton from '../../components/CancelButton';

const columns = [
    { id: 'name', label: 'Nombre' },
    { id: 'nit', label: 'NIT' },
    { id: 'number_of_employees', label: 'Numero de empleados' },
    { id: 'phone', label: 'Celular' },
    { id: 'consultant', label: 'Consultor' },
    { id: 'action', label: 'Agregar/Cambiar Consultor' },
];

const JoinConsultant = () => {
    const [companies, setCompanies] = useState<CompanyAdminData[]>([]);
    const [consultants, setConsultants] = useState<ConsultantData[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined);
    const [showSuccess, setShowSuccess] = useState(false);
    const [next, setNext] = useState<string | undefined>(undefined);
    const [previous, setPrevious] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const fetchDataCompanies = (url?: string) => {
        setErrorMessage("")
        adminService.companiesData(
            (response) => {
                setCompanies(response.results);
                setNext(response.next);
                setPrevious(response.previous);
            },
            (error) => {
                setErrorMessage("Error al obtener las empresas")
            },
            url
        );


    };
    useEffect(() => {
        fetchDataCompanies()
        adminService.consultantsData(
            (response) => {
                setConsultants(response.results);
            },
            (error) => {
                setErrorMessage("Error al obtener los consultores")
            }
        );

    }, []);

    const updateConsultantForCompany = (companyId: string, consultantId: string | undefined) => {
        setErrorMessage("")
        if (!consultantId) return;
        console.log(consultantId, companyId)

        setLoading(true);
        adminService.updateCompanyConsultant(consultantId, companyId,
            () => {
                setLoading(false);
                setShowModal(false);
                setShowSuccess(true);
                setSuccessMessage('Consultor actualizado correctamente.');
            },
            (error) => {
                setErrorMessage("Hubo un error al actualizar el consultor.")
                setLoading(false);

            }
        );
    };
    const closeModal = () => {
        setShowSuccess(false);
        setSuccessMessage("");
        fetchDataCompanies()
    };

    const handleConsultantChange = (companyId: string | undefined) => {
        setSelectedCompanyId(companyId);
        setShowModal(true);
    };

    const handleConsultantAdd = (companyId: string | undefined) => {
        setSelectedCompanyId(companyId);
        setShowModal(true);
    };

    const handleSelectConsultant = (consultantId: string) => {
        updateConsultantForCompany(selectedCompanyId || '', consultantId);
    };

    return (
        <MainLayout>
            <div className="grid text-center items-center text-secondaryBrown">
                <h1 className="text-4xl text-start font-bold mb-16 text-primaryOrange">
                    Vincular Consultor con empresa
                </h1>
                <span className='text-m text-primaryRed text-center my-4'>{errorMessage}</span>
                <div className="text-sm overflow-x-auto w-full max-h-[600px]">
                    <table className="min-w-full bg-white border-separate border border-spacing-x-2 border-transparent">
                        <thead className="sticky top-0 bg-white">
                            <tr>
                                {columns.map((column) => (
                                    <th
                                        key={column.id}
                                        className="text-center z-10 px-2 py-4 text-m font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg"
                                    >
                                        {column.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {companies.length > 0 ? (
                                companies.map((company) => (
                                    <tr key={company.id} className="hover:bg-gray-100">
                                        <td className="px-2 py-4 whitespace-nowrap">
                                            {company.name}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {company.nit}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {company.number_of_employees}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {company.phone}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {company.consultant?.email || 'Sin consultor'}
                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            {company.consultant ? (
                                                <Button
                                                    onClick={() => handleConsultantChange(company.id)}
                                                    text="Cambiar Consultor"
                                                />
                                            ) : (
                                                <Button
                                                    onClick={() => handleConsultantAdd(company.id)}
                                                    text="Agregar Consultor"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        className="px-6 py-4 text-center text-gray-500"
                                    >
                                        No hay Empresas.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between gap-5 mt-4">
                    <button
                        className="p-2 border border-gray-300 rounded bg-gray-200"
                        color="primary"
                        disabled={!previous}
                        onClick={() => fetchDataCompanies(previous)}
                    >
                        Anterior
                    </button>
                    <button
                        className="p-2 border border-gray-300 rounded bg-gray-200"
                        color="primary"
                        disabled={!next}
                        onClick={() => fetchDataCompanies(next)}
                    >
                        Siguiente
                    </button>
                </div>
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg shadow-lg h-auto w-[500px] border-2 border-secondaryBrown max-h-full overflow-y-auto  flex flex-col">
                        <div className="flex justify-center text-center min-h-[128px] mobile:h-auto bg-[#fdfced] rounded-t-lg">
                            <div className="flex justify-center items-center p-4">
                                <div className='grid'>
                                    <h1 className="mobile:text-2xl text-4xl font-rounded font-semibold text-primaryOrange">
                                        Selecciona un consultor
                                    </h1>
                                    <div className="flex text-xl justify-center text-secondaryBrown mt-2">
                                        <span>Lista de consultores</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex-grow flex justify-center overflow-y-auto px-4 py-2">
                            <div className="w-full max-w-md">
                                <select
                                    id="consultant-select"
                                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primaryOrange"
                                    onChange={(e) => handleSelectConsultant(e.target.value)}
                                    defaultValue=""
                                >
                                    <option value="" disabled>Consultores</option>
                                    {consultants.map((consultant) => (
                                        <option key={consultant.id} value={consultant.id}>
                                            {consultant.email}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="flex-shrink-0 flex justify-center px-4 py-4 mt-auto">
                            <CancelButton
                                onClick={() => setShowModal(false)}
                                text="Cerrar"
                            />
                        </div>

                    </div>
                </div>

            )}

            {loading && (
                <div className="fixed inset-0 flex justify-center items-center bg-gray-300 bg-opacity-50">
                    <span className="text-white">Cargando...</span>
                </div>
            )}
            {showSuccess && (
                <SuccessModal
                    message={successMessage}
                    buttonText="Continuar"
                    onClose={closeModal}
                />
            )}
        </MainLayout>
    );
};

export default JoinConsultant;
