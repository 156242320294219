import React from 'react';
import redPoint from '../assets/icons/red-point.svg'

type ItemTextNumberProps = {
  text: string;
  number: string;
};

const ItemTextNumber: React.FC<ItemTextNumberProps> = ({ text, number }) => {
  return (
    <div className='flex justify-between items-center gap-4'>
      <div className='flex gap-3'>
        <p className='text-lg font-semibold text-secondaryBrown'>{text}</p>
      </div>
      <span className='text-2xl font-semibold text-black'>{number}</span>
    </div>
  );
};

export default ItemTextNumber;