import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface AutoevaluationResponse {
  success: boolean;
  message: string;
  data: any;
}

export class AutoevaluationData {
  health_safety_management_responsibility: string;
  safety_management_duties: string;
  resource_allocation_for_safety_management: string;
  affiliation_to_general_risk_system: string;
  identification_of_high_risk_workers: string;
  copasst_formation: string;
  copasst_training: string;
  convivence_committee_formation: string;
  training_program_promotion_and_prevention: string;
  induction_and_reinduction: string;
  certified_responsibles_in_safety_management: string;
  signed_safety_policy: string;
  clear_measurable_goals: string;
  priority_evaluation_and_identification: string;
  action_plan_with_goals_and_timeline: string;
  documentation_retention: string;
  performance_reporting: string;
  legal_matrix: string;
  communication_mechanisms: string;
  evaluation_for_procurement: string;
  supplier_evaluation: string;
  impact_evaluation_of_internal_external_changes: string;
  sociodemographic_description: string;
  health_promotion_activities: string;
  occupational_medical_info: string;
  occupational_medical_evaluations: string;
  medical_records_custody: string;
  medical_labour_restrictions: string;
  healthy_lifestyle_environments: string;
  drinking_water_and_sanitation: string;
  waste_disposal: string;
  accident_and_disease_reporting: string;
  incident_investigation: string;
  accident_disease_statistics: string;
  accident_frequency_measurement: string;
  accident_severity_measurement: string;
  work_accident_mortality_measurement: string;
  occupational_disease_prevalence_measurement: string;
  occupational_disease_incidence_measurement: string;
  medical_cause_absenteeism_measurement: string;
  hazard_identification_methodology: string;
  all_level_hazard_identification: string;
  carcinogenic_toxic_substance_identification: string;
  environmental_measurements: string;
  hazard_prevention_and_control: string;
  prevention_control_verification: string;
  procedural_documentation: string;
  systematic_inspections: string;
  periodic_maintenance: string;
  personal_protection_equipment_distribution: string;
  emergency_preparedness_plan: string;
  prevention_brigade: string;
  sst_indicators_definition: string;
  annual_audit: string;
  annual_audit_review: string;
  audit_planning: string;
  preventive_corrective_actions: string;
  improvement_actions_based_on_reviews: string;
  accident_investigation_improvement_actions: string;
  improvement_plan_implementation: string;
  employee: number;
  total_points: 0

  constructor(completedBy: number, data: { [key: string]: boolean | string }) {
    Object.keys(data).forEach(key => {
      if (key in this) {
        (this as any)[key] = data[key];
      }
    });
    this.employee = completedBy
  }
}

type OnSuccess = (response: AutoevaluationResponse) => void;
type OnError = (error: any) => void;

const submitAutoevaluation = async (autoevaluationData: AutoevaluationData, onSuccess: OnSuccess, onError: OnError) => {
  try {
    const companyId = localStorage.getItem('company_id') ?? '';
    const response = await axios.post<AutoevaluationResponse>(`${API_URL}/companies/auth/autoevaluation`, autoevaluationData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          'Content-Type': 'application/json',
          'X-Company-Id': companyId
        },
      });
    onSuccess(response.data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      onError(error.response?.data || "Unknown error");
    } else {
      onError("An unexpected error occurred");
    }
  }
};




export interface ImprovementPlanData extends PlanDataServiceInterface {
  id: string;
  state: string;
}

interface ImprovementPlanResponse {
  success: boolean;
  message: string;
  data: any;
}

type OnSuccessImprovementPlan = (response: ImprovementPlanResponse) => void;

const putImprovementPlan = async (data: ImprovementPlanData, onSuccess: OnSuccessImprovementPlan, onError: OnError) => {
  try {
    const companyId = localStorage.getItem('company_id') ?? '';
    const response = await axios.put<ImprovementPlanResponse>(
      `${API_URL}/companies/auth/improvement-plan/${data.id}`,
      data,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          'Content-Type': 'application/json',
          'X-Company-Id': companyId
        },
      }
    );
    onSuccess(response.data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      onError(error.response?.data || "Unknown error");
    } else {
      onError("An unexpected error occurred");
    }
  }
};

const deleteImprovementPlan = async (
  id: string,
  onSuccess: any,
  onError: OnError
) => {
  try {
    const companyId = localStorage.getItem('company_id') ?? '';
    await axios.delete<ImprovementPlanResponse>(
      `${API_URL}/companies/auth/improvement-plan/${id}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          'Content-Type': 'application/json',
          'X-Company-Id': companyId
        },
      }
    );
    onSuccess();
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        onSuccess();

      } else {
        onError(error.response?.data || "Unknown error");
      }
    } else {
      onError("An unexpected error occurred");
    }
  }
};




export interface PlanDataResponse {
  success: boolean;
  message: string;
  data: any;
}

export interface PlanDataServiceInterface {
  item_name?: string;
  customized_item_name?: string;
  activity?: string;
  manager?: number;
  participants?: number[];
  start_date?: string;
  end_date?: string;
  is_work_plan?: boolean;
  is_training_plan?: boolean;
  is_customized_item?: boolean;
}

type OnSuccessPlan = (response: PlanDataResponse) => void;
type OnErrorPlan = (error: any) => void;

const submitPlanData = async (planData: PlanDataServiceInterface[], onSuccess: OnSuccessPlan, onError: OnErrorPlan) => {
  try {
    const companyId = localStorage.getItem('company_id') ?? '';
    const response = await axios.post<PlanDataResponse>(`${API_URL}/companies/auth/improvement-plan`, planData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json',
        'X-Company-Id': companyId
      },
    });
    onSuccess(response.data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      onError(error.response?.data || "Unknown error");
    } else {
      onError("An unexpected error occurred");
    }
  }
};

export interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  id_card: string;
}

export interface PlanDataServiceInterfaceResponse {
  id?: string;
  item_name?: string;
  activity?: string;
  manager?: Employee;
  participants?: Employee[];
  start_date?: string;
  end_date?: string;
  is_work_plan?: boolean;
  is_training_plan?: boolean;
  company?: string;
  created_at?: string;
}

type OnSuccessDataResponse = (response: PlanDataServiceInterfaceResponse) => void;
type OnErrorDataResponse = (error: any) => void;

const getImprovementPlan = (item_name: string, onSuccess: OnSuccessDataResponse, onError: OnErrorDataResponse): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get<PlanDataServiceInterfaceResponse>(`${API_URL}/companies/auth/improvement-plan`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      item_name: item_name
    }
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export interface ImprovementPlansData {
  id: string;
  item_name?: string;
  customized_item_name?: string;
  is_customized_item: boolean,
  is_work_plan?: boolean;
  is_training_plan?: boolean;
  activity?: string;
  manager?: Employee;
  participants?: Employee[];
  start_date?: string;
  end_date?: string;
  state?: string;
  created_at?: string;
}
export interface PlansDataInterfaceResponse {
  customized_items: ImprovementPlansData[];
  standard_items: ImprovementPlansData[];
}

type OnSuccessPlans = (response: PlansDataInterfaceResponse) => void;

const getImprovementPlans = (year: number, type: string, onSuccess: OnSuccessPlans, onError: OnErrorDataResponse): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get<PlansDataInterfaceResponse>(`${API_URL}/companies/auth/improvement-plans`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      year: year,
      type: type
    }

  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export interface PlansPercentageInterfaceResponse {
  work_plan__percentage: number;
  training_plan__percentage: number;
  customized_plans: ImprovementPlansData[];
  improvement_plans_list: ImprovementPlansData[];
}

type OnSuccessPlansPercentage = (response: PlansPercentageInterfaceResponse) => void;


const getImprovementPlansPercentage = (year: number, onSuccess: OnSuccessPlansPercentage, onError: OnErrorDataResponse): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get<PlansPercentageInterfaceResponse>(`${API_URL}/companies/auth/improvement-plans`, {

    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      year: year,
    }
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export class GetImprovementPlansByEmployeeParams {
  year: number;
  employeeId?: number;

  constructor(
    year: number,
    employeeId?: number,

  ) {
    this.year = year;
    this.employeeId = employeeId;
  }
}

export interface PlansPerEmployeeResponse {
  improvement_plans: ImprovementPlansData[]
}

type OnSuccessPlansPerEmployee = (response: PlansPerEmployeeResponse) => void;

const getImprovementPlansPerEmployee = (getImprovementPlansByEmployeeParams: GetImprovementPlansByEmployeeParams, onSuccess: OnSuccessPlansPerEmployee, onError: OnErrorDataResponse): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get<PlansPerEmployeeResponse>(`${API_URL}/companies/auth/improvement-plans`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      year: getImprovementPlansByEmployeeParams.year,
      employee_id: getImprovementPlansByEmployeeParams.employeeId
    }
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};


export class AutoevaluationDataResponse {
  health_safety_management_responsibility: string;
  safety_management_duties: string;
  resource_allocation_for_safety_management: string;
  affiliation_to_general_risk_system: string;
  identification_of_high_risk_workers: string;
  copasst_formation: string;
  copasst_training: string;
  convivence_committee_formation: string;
  training_program_promotion_and_prevention: string;
  induction_and_reinduction: string;
  certified_responsibles_in_safety_management: string;
  signed_safety_policy: string;
  clear_measurable_goals: string;
  priority_evaluation_and_identification: string;
  action_plan_with_goals_and_timeline: string;
  documentation_retention: string;
  performance_reporting: string;
  legal_matrix: string;
  communication_mechanisms: string;
  evaluation_for_procurement: string;
  supplier_evaluation: string;
  impact_evaluation_of_internal_external_changes: string;
  sociodemographic_description: string;
  health_promotion_activities: string;
  occupational_medical_info: string;
  occupational_medical_evaluations: string;
  medical_records_custody: string;
  medical_labour_restrictions: string;
  healthy_lifestyle_environments: string;
  drinking_water_and_sanitation: string;
  waste_disposal: string;
  accident_and_disease_reporting: string;
  incident_investigation: string;
  accident_disease_statistics: string;
  accident_frequency_measurement: string;
  accident_severity_measurement: string;
  work_accident_mortality_measurement: string;
  occupational_disease_prevalence_measurement: string;
  occupational_disease_incidence_measurement: string;
  medical_cause_absenteeism_measurement: string;
  hazard_identification_methodology: string;
  all_level_hazard_identification: string;
  carcinogenic_toxic_substance_identification: string;
  environmental_measurements: string;
  hazard_prevention_and_control: string;
  prevention_control_verification: string;
  procedural_documentation: string;
  systematic_inspections: string;
  periodic_maintenance: string;
  personal_protection_equipment_distribution: string;
  emergency_preparedness_plan: string;
  prevention_brigade: string;
  sst_indicators_definition: string;
  annual_audit: string;
  annual_audit_review: string;
  audit_planning: string;
  preventive_corrective_actions: string;
  improvement_actions_based_on_reviews: string;
  accident_investigation_improvement_actions: string;
  improvement_plan_implementation: string;
  company: string;
  total_points: 0;
  created_at: string;

  constructor(data: { [key: string]: boolean | string }) {
    Object.keys(data).forEach(key => {
      if (key in this) {
        (this as any)[key] = data[key];
      }
    });
  }
}

type OnSuccessData = (response: AutoevaluationDataResponse) => void;
type OnErrorData = (error: any) => void;

const getAutoevaluation = (onSuccess: OnSuccessData, onError: OnErrorData): void => {

  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get<AutoevaluationDataResponse>(`${API_URL}/companies/auth/autoevaluation`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

type OnSuccessPdf = (response: any) => void;
type OnErrorPdf = (response: any) => void;

const autoevaluationPdf = (onSuccess: OnSuccessPdf, onError: OnErrorPdf): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get(`${API_URL}/companies/autoevaluation/pdf`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    responseType: 'blob'
  })
    .then(response => onSuccess(response))
    .catch(error => onError(error));
};

const improvementPlansPdf = (onSuccess: OnSuccessPdf, onError: OnErrorPdf, title: string): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.get(`${API_URL}/companies/improvement-plans/pdf`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params: {
      type_improvement_plan: title,
    },
    responseType: 'blob'
  })
    .then(response => onSuccess(response))
    .catch(error => onError(error));
};


export const autoevaluationService = {
  submitAutoevaluation,
  submitPlanData,
  getAutoevaluation,
  getImprovementPlan,
  getImprovementPlans,
  putImprovementPlan,
  getImprovementPlansPercentage,
  autoevaluationPdf,
  improvementPlansPdf,
  getImprovementPlansPerEmployee,
  deleteImprovementPlan
};