/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from 'react';
import { companyService, CompanyData } from '../../services/companyServices';
import { daneService, Data } from '../../services/daneService';
import jsonDataRisk from '../../utils/riskClass.json'
import SuccessModal from '../../components/SuccessModal';
import Button from '../../components/Button';
import TextFieldString from '../../components/TextFieldString';
import HeaderSurveys from '../../components/HeaderSurveys';
import { mainDivClassName } from '../../utils/MainDivClassName';
import SelectInput from '../../components/SelectInput';
import SelectInputNumber from '../../components/SelectInputNumber';
import TextFieldNumber from '../../components/TextFieldNumber';
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import { EvidenceRequest, EvidenceResponse, uploadImageService, UploadRequest } from '../../services/uploadImageServices';


const risks = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 }
]

export const arls = [
  { label: "AXXA COLPATRIA", value: "AXXA COLPATRIA" },
  { label: "BOLIVAR", value: "BOLIVAR" },
  { label: "COLMENA", value: "COLMENA" },
  { label: "LA EQUIDAD", value: "LA EQUIDAD" },
  { label: "LIBERTY", value: "LIBERTY" },
  { label: "POSITIVA", value: "POSITIVA" },
  { label: "SURA", value: "SURA" },
];

interface CodesFromRisk {
  value: string;
  label: string;
}

const FormCompany = () => {
  const [name, setName] = useState<string>('');
  const [nit, setNit] = useState<number | null>(null);
  const [phone, setPhone] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [arl, setArl] = useState<string>('');
  const [logoFile, setLogoFile] = useState<File>();
  const [activityCode, setActivityCode] = useState<string>('0');
  const [codes, setCodes] = useState<CodesFromRisk[]>([]);
  const [selectedState, setSelectedState] = useState<string>('')
  const [numberOfEmployes, setNumberOfEmployes] = useState<number | null>(null);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<number>(0)
  const [cities, setCities] = useState<Data[]>([])
  const [data, setData] = useState<Data[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const onDropLogo = useCallback((acceptedFiles: File[]) => {
    setLogoFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropLogo,
    multiple: false,
  });

  const options = codes.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const handleChangeRisk = (value: number) => {
    setSelectedRiskLevel(value);

    const codesFromRisk = jsonDataRisk.filter
      (item => item.CLASE_DE_RIESGO === value).map(item => ({
        value: item.CODIGO_CIIU,
        label: `${item.CODIGO_CIIU} - ${(item.ACTIVIDAD_ECONOMICA)?.toLowerCase()}`
      }));

    setCodes(codesFromRisk)
  };
  const handleChangeArl = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setArl(value);

  };

  const handleChangeActivity = (selectValue: string) => {
    setActivityCode(selectValue);
  }


  const handleChangeDepartament = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const departmentCode = event.target.value;
    setSelectedState(departmentCode);

    const citiesFromDepartment = data
      .filter(item => item.c_digo_dane_del_departamento === departmentCode);

    setCities(citiesFromDepartment);
  };

  const handleChangeCity = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCity(event.target.value);
  }

  useEffect(() => {
    daneService.listDaneData(
      (response) => {
        setData(response);
      },
      (err) => {
        // Manejo de errores si es necesario
        console.error(err);
      }
    );
  }, []);


  const handleClickImage = async (): Promise<string | null> => {
    if (logoFile === undefined) return null;
    try {
      setLoading(true);
      const generateUrlResponse = await generateUrlPromise(new EvidenceRequest(logoFile.name));

      await uploadImagePromise(logoFile, generateUrlResponse.url, generateUrlResponse.final_url);

      setLoading(false);
      return generateUrlResponse.final_url;

    } catch (err) {
      console.error('Error durante el procesamiento del archivo:', err);
      setErrorMessage('Error en el envío del logo de la empresa.');
      setLoading(false);
      return null;
    }
  };

  const handleClick = async () => {
    setLoading(true);

    if (!name || !nit || !phone || !city || !address || !arl || !numberOfEmployes || selectedRiskLevel <= 0) {
      setErrorMessage('Todos los campos son obligatorios excepto el logo.');
      setLoading(false);
      return;
    }

    let finalLogoUrl = "";

    if (logoFile) {
      const logoUrl = await handleClickImage();

      if (logoUrl) {
        finalLogoUrl = logoUrl;
      }
    }

    companyService.register(
      new CompanyData(
        name, nit.toString(), phone, city, address, selectedState, activityCode, numberOfEmployes, selectedRiskLevel.toString(), arl, finalLogoUrl // Usamos la URL del logo o cadena vacía
      ),
      () => {
        setLoading(false);
        setShowModal(true);
      },
      (err) => {
        setLoading(false);
        setErrorMessage('Error en el envío de datos, verifica que todos los datos están correctos');
      }
    );
  };


  const generateUrlPromise = (request: EvidenceRequest): Promise<EvidenceResponse> => {
    return new Promise<EvidenceResponse>((resolve, reject) => {
      uploadImageService.generateLogoUrl(request, (response: EvidenceResponse) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  };

  const uploadImagePromise = (file: File, url: string, finalUrl: string) => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        await uploadImageService.uploadImage(
          new UploadRequest(file, url),
          response => {
            resolve(finalUrl);
          },
          error => {
            setErrorMessage('Error al subir la imagen.');
            reject(error);
          }
        );
      } catch (error) {
        setErrorMessage('Error en el servicio de subida.');
        reject(error);
      }
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const sortedCities = [...cities].sort((a, b) => a.municipio.localeCompare(b.municipio));
  const sortedDepartments = [...data]
    .reduce<Data[]>((unique, item) => {
      if (!unique.some(dept => dept.c_digo_dane_del_departamento === item.c_digo_dane_del_departamento)) {
        unique.push(item);
      }
      return unique;
    }, [])
    .sort((a, b) => a.departamento.localeCompare(b.departamento));
  return (
    <>
      {showModal && (
        <SuccessModal
          message="Información enviada con éxito"
          buttonText="Continuar"
          redirectPath="/formEmployees"
          onClose={handleCloseModal}
        />
      )}

      <div className={`grid items-center  h-screen mobile:h-auto  ${mainDivClassName}  p-2`}>
        <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-2 border-secondaryBrown place-content-center">
          <HeaderSurveys title="Configuración inicial" />
          <div className='grid grid-cols-2 mobile:grid-cols-1 p-4 gap-4 items-end text-secondaryBrown'>
            <TextFieldString
              label="Razón Social"
              value={name}
              onChange={(e) => setName(e.target.value)}
              title='Nombre legal de tu empresa como está registrado oficialmente'
            />
            <TextFieldNumber
              label="NIT"
              value={nit}
              onChange={(e) => setNit(isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber)}
              title='Número de Identificación Tributaria de tu empresa. (Agrega el NIT sin digito de verificación, sin letras y sin carácteres especiales)'
            />
            <SelectInput
              label="ARL"
              id='arl-company'
              value={arl}
              onChange={handleChangeArl}
              options={arls}
              title='Selecciona la clase de riesgo asociada a las actividades principales de tu empresa, de acuerdo con la tabla de clasificación de riesgos laborales'
            />
            <TextFieldString
              label="Número de teléfono"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="w-full flex flex-col mb-4">
              <label className="mb-1 text-m ">Departamento</label>
              <select
                className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full"
                id="department"
                value={selectedState}
                onChange={handleChangeDepartament}
              >
                <option value="">Selecciona...</option>
                {sortedDepartments.map((item, index) => (
                  <option key={index} value={item.c_digo_dane_del_departamento}>
                    {item.departamento}
                  </option>
                ))}
              </select>
            </div>
            <TextFieldString
              label="Dirección"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              title='Proporciona la dirección completa de la ubicación principal de tu empresa'
            />
            <div className='w-full flex flex-col mb-4'>
              <label className='mb-1 text-m' >Ciudad : </label>
              <select
                className='"border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full'
                id="city"
                value={city}
                onChange={handleChangeCity}>
                <option >Selecciona...</option>

                {sortedCities.map((city, index) => (
                  <option key={index} value={city.c_digo_dane_del_municipio}>
                    {city.municipio}
                  </option>
                ))}

              </select>
            </div>
            <SelectInputNumber
              label="Clase de riesgo "
              id='risk-company'
              value={selectedRiskLevel}
              onChange={handleChangeRisk}
              options={risks}
              title='Selecciona la clase de riesgo asociada a las actividades principales de tu empresa, de acuerdo con la tabla de clasificación de riesgos laborales'
            />
            <TextFieldNumber
              label="Número de empleados"
              value={numberOfEmployes}
              onChange={(e) => setNumberOfEmployes(isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber)}
              title='Total de empleados que trabajan en tu empresa'
            />
            <div className='w-full flex flex-col mb-4'>
              <label className='mb-1 text-m text-center'>
                Código CIIU actividad económica principal de la empresa:
              </label>

              <Select
                className="bg-secondaryGray rounded-md  desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full text-m"
                value={options.find(option => option.value === activityCode)}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    handleChangeActivity(selectedOption.value);
                  }
                }}
                options={options}
                placeholder="Selecciona..."
                isSearchable={true}
                noOptionsMessage={() => "No se encontraron opciones"}
              />
            </div>
            <div className='w-full flex flex-col col-span-2 mobile:col-span-1 mb-4'>
              <label className='mb-1 text-m text-center'>
                Añadir logo ( Opcional )
              </label>
              <div className='flex w-full justify-between gap-4'>
                <div className='grid w-full '>
                  <div className="flex w-full justify-center items-center font-semibold bg-primaryOrange hover:bg-secondaryOrange  min-w-[100px] h-[36px] text-center text-white rounded-md cursor-pointer" {...getRootProps()} >
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Suelta el logo aquí</p> : <p>Añade el logo</p>}
                  </div>
                  <div className="flex justify-center items-center">
                    {logoFile && (
                      <img
                        src={URL.createObjectURL(logoFile)}
                        alt="Logo preview"
                        style={{ width: '70px', height: '70px' }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className='animate-pulse text-center text-red-600 text-sm '>
            {errorMessage && <div>{errorMessage}</div>}
          </span>
          <div className="flex justify-center my-4">
            <Button
              text='Enviar formulario'
              onClick={handleClick}
              disabled={loading}

            />
          </div>
        </div>
      </div>
    </>
  )
}
export default FormCompany
