import React from 'react';

interface TextFieldStringProps {
  label: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  type?: string;  // Nueva propiedad para el tipo de input
  disableCut?: boolean;
  disablePaste?: boolean;
}

const TextFieldString: React.FC<TextFieldStringProps> = ({ label, value, onChange, title, type = 'text', disableCut, disablePaste }) => {
  // Función para evitar el cortar o pegar
  const handlePreventAction = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <div className="flex flex-col mb-4 w-full">
      <label className="mb-1 text-m">{label}</label>
      <input
        type={type}  // Utiliza el valor de la propiedad type, por defecto será 'text'
        title={title}
        value={value}
        onChange={onChange}
        onCut={disableCut ? handlePreventAction : undefined}
        onPaste={disablePaste ? handlePreventAction : undefined}
        className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] mobile:w-full"
      />
    </div>
  );
};

export default TextFieldString;
