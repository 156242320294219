import { useEffect, useState } from 'react';
import { adminService } from '../../services/adminService';
import Button from '../../components/Button';
import MainLayout from '../../components/MainLayout';
import { mainDivClassName } from '../../utils/MainDivClassName';

interface QrCodeViewProps {
}

const QrCodeView: React.FC<QrCodeViewProps> = ({ }) => {
    const [qrCode, setQrCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessageQr, setErrorMessageQr] = useState<string>('');
    const [successMessaageQr, setSuccessMessageQr] = useState<string>('');
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

    const cleanData = () => {
        setQrCode('');
        setErrorMessageQr('');
        setSuccessMessageQr('')
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };

    useEffect(() => {
        handleConnectWhatsapp()
    }, []);

    const handleConnectWhatsapp = () => {
        cleanData();
        setLoading(true)
        adminService.whatsappQr(
            (response) => {
                setQrCode(response.qr_code);
                setErrorMessageQr('');
                checkWhatsappStatus();
                setLoading(false)

            },
            (errorMessage) => {
                if (errorMessage == "ya existe una cuenta conectada con la API") {
                    setSuccessMessageQr(errorMessage)
                    setLoading(false)

                } else {
                    setErrorMessageQr(errorMessage);
                    setLoading(false)
                }
            }
        );
        setLoading(false)
    };

    const checkWhatsappStatus = () => {
        const newIntervalId = setInterval(() => {
            adminService.whatsappStatus(
                (response) => {
                    if (response.estado === "activo") {
                        clearInterval(newIntervalId);
                        setIntervalId(null);
                        setQrCode("");
                    }
                },
                (error) => {
                    clearInterval(newIntervalId);
                    setIntervalId(null);
                    console.log("Error en la solicitud, deteniendo verificación:", error);
                }
            );
        }, 1000);
        setIntervalId(newIntervalId);
    };

    return (
        <MainLayout>
            <div className={"mobile:p-2 flex h-screen justify-center items-center text-secondaryBrown " + mainDivClassName}>
                <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-secondaryBrown border-2 relative">
                    <div className="flex flex-col gap-4 p-4 min-h-[480px] justify-between items-center ">
                        <div className="flex justify-center text-center min-h-[128px] mobile:h-auto bg-[#fdfced] rounded-t-lg">
                            <div className="flex justify-center items-center p-4">
                                <div className="grid">
                                    <h1 className="mobile:text-2xl text-4xl font-rounded font-semibold text-primaryOrange">
                                        Escanea el código QR para conectar a WhatsApp
                                    </h1>
                                    <div className="flex w-full text-center text-sm text-secondaryBrown my-2">
                                        Cuando confirmes la conexión de tu WhatsApp con la aplicación, puedes cerrar la sesión o navegar a otras vistas.
                                    </div>
                                </div>
                            </div>
                        </div>
                        {qrCode ? (
                            <img src={`data:image/jpeg;base64,${qrCode}`} alt="QR Code" className="w-70 h-70" />
                        ) : (
                            <div>
                                {errorMessageQr && (
                                    <p className="text-primaryRed my-4">{errorMessageQr}</p>
                                )}
                                {!errorMessageQr && !successMessaageQr && (
                                    <p className="text-primaryRed my-4">Genere el codigo QR</p>
                                )}
                                {successMessaageQr && (
                                    <p className="text-primaryGreen my-4">{successMessaageQr}</p>
                                )}
                            </div>

                        )}
                        <div className="flex justify-center gap-7 pb-4">
                            <Button text="Generar QR" onClick={handleConnectWhatsapp} disabled={loading} />
                        </div>

                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default QrCodeView;
