import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface ConnectWhatsappResponse {
    qr_code: string;
}

type OnSuccess = (response: ConnectWhatsappResponse) => void;

type OnError = (error: string) => void;

const whatsappQr = (onSuccess: OnSuccess, onError: OnError): void => {
    axios.post<ConnectWhatsappResponse>(`${API_URL}/administrator/whatsapp/qr`, {}, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json'
        },
    })
        .then(response => onSuccess(response.data))
        .catch(error => {
            const errorMessage = error.response?.data?.error || 'Error desconocido';
            onError(errorMessage);
        });
};

interface CheckStatusResponse {
    estado: string;
}

type OnSuccesCheck = (response: CheckStatusResponse) => void;
const whatsappStatus = (onSuccess: OnSuccesCheck, onError: OnError): void => {
    axios.get<CheckStatusResponse>(`${API_URL}/administrator/whatsapp/status`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json'
        },
    })
        .then(response => onSuccess(response.data))
        .catch(error => {
            const errorMessage = error.response?.data?.error || 'Error desconocido';
            onError(errorMessage);
        });
};

export interface ConsultantData {
    id: string;
    email: string;
}

export interface CompanyAdminData {
    id?: string;
    name: string;
    nit: string;
    phone: string;
    city: string;
    address: string;
    state: string;
    activity_code: string;
    number_of_employees: number;
    consultant: ConsultantData | null;
    risk_level: string;
    arl: string;
    logo_url: string | null;
}

export interface CompaniesDataResponse {
    results: CompanyAdminData[];
    count: number;
    next: string;
    previous: string;
}

type OnSuccessData = (response: CompaniesDataResponse) => void;

type OnErrorData = (error: any) => void;

const companiesData = (onSuccess: OnSuccessData, onError: OnErrorData, url?: string): void => {
    const requestUrl = url || `${API_URL}/administrator/companies`;

    axios.get<CompaniesDataResponse>(requestUrl, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};


interface ConsultantDataResponse {
    results: ConsultantData[];
    count: number;
    next: string;
    previous: string;

}

type OnSuccessConsultantData = (response: ConsultantDataResponse) => void;


const consultantsData = (onSuccess: OnSuccessConsultantData, onError: OnErrorData): void => {
    axios.get<ConsultantDataResponse>(`${API_URL}/administrator/consultants`,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => onSuccess(response.data)
        )
        .catch(error => onError(error)
        )
};

const updateCompanyConsultant = (
    consultantId: string,
    companyId: string,
    onSuccess: any,
    onError: OnErrorData
): void => {
    axios.put<CompaniesDataResponse>(
        `${API_URL}/administrator/update/company/${companyId}`,
        {
            consultant_id: consultantId,
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',
            },
        }
    )
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

export const adminService = {
    whatsappQr,
    whatsappStatus,
    companiesData,
    consultantsData,
    updateCompanyConsultant
};